import React from 'react'
import { TermsProps } from '../../@interface/terms'
import AttachmentRelationship from '../../images/terms/attachments/programa_relacionamento_coraliados.png'
import Link from '../../components/Link'
import MaterialGuide from '../../docs/Material_Completo_Guia_de_Boas_Praticas.pdf'

export const dataTermsCoraliados: TermsProps = {
  title: 'Termos e Condições de Coraliados',
  subtitle: 'de Coraliados',
  update: '30 de setembro de 2024',
  about: {
    title: 'Termos e Condições',
    introduction: `Este documento (“Termos e Condições”) apresenta as condições de participação do Programa de Parceria Comercial da Cora (“CORALIADOS”) da CORA SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A. – “CORA SCFI”, sociedade inscrita sob o nº 37.880.206/0001-63 (“CORA” ou “Nós”) e qualificada conforme <a target='blank' href="https://www.cora.com.br/termos-uso/">Termos e Condições Gerais de Uso da Conta Cora</a>. Ao cadastrar-se no Programa de Parceria Comercial, você celebra um contrato com a CORA e assume os direitos e obrigações que apresentamos ao longo destes Termos de Uso, para captação de potenciais novos negócios.Você deve ler atentamente estes Termos de Uso, por inteiro, pois a afiliação como CORALIADO implica sua total concordância com as disposições deste documento. Caso não concorde com qualquer disposição deste instrumento, não se cadastre no Programa de Parceria Comercial.
    <br /><br />
    As Condições da Parceria são regidas pelo Quadro Resumo e pelas cláusulas abaixo:
    <br /><br />
    <div class="table-privacy">
      <table>
        <thead>
          <th colspan="3" align="left"><b>Condições de Parceria</b></th>
        </thead>
        <tbody>
          <tr>
            <td>
            REMUNERAÇÃO
            ("Receita Líquida")
            </td>
            <td>
              <u>Comissão por Conta Ativada</u>: Os valores e critérios de pagamento podem ser verificados pelo site: <a target='blank' href="https://comunidade.cora.com.br/termo/coraliados/comissionamento/">https://comunidade.cora.com.br/termo/coraliados/comissionamento/</a>
            </td>
          </tr>
          <tr>
            <td>FORMA DE PAGAMENTO
            (Comissão por Conta Ativada será feita por meio de depósito na Conta Cora de titularidade da PARCEIRA)
            </td>
            <td>
              Crédito em Conta <br/>
              Banco Cora (403)<br/>
              Agência: 0001<br/>
              Conta Cora do Coraliado
            </td>
          </tr>
          <tr>
            <td>
            PRAZO E REGRAS DE PAGAMENTO
            </td>
            <td>
            Após apuração mensal, feita pela Cora, das indicações válidas para comissionamento, o Coraliado deverá, em até 3 (três) meses, anexar no Aplicativo Cora, na seção "Comissões" presente na área "Coraliados", a Nota Fiscal de Serviço Eletrônica, formato XML, com as orientações especificadas no produto:</br>
            Tipo: Nota Fiscal de Serviço Eletrônica (NFS-e);</br>
            Valor: o valor da Nota deve ser igual ao informado na coluna "valor a receber";</br>
            Código de serviço (ou Item da Lei): preferencialmente 10.02 ou 10.09. Caso não consiga usar nenhum dos códigos acima, utilizar 17.01, 17.02, 17.19 ou 17.23;</br>
            Dados do prestador do serviço: a nota deve estar em nome da sua empresa cadastrada aqui na Cora, ou seja, mesmo CNPJ cadastrado na parceria;</br>
            Dados do tomador do serviço:</br>
            Razão Social: CORA SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A. – “CORA SCFI”</br>
            CNPJ: 37.880.206/0001-63</br>
            Endereço: Rua Frei Caneca, nº 1246, 3º andar, sala 31. CEP: 01307-002. Consolação - São Paulo - SP.</br>
            Email: financeiro@cora.com.br</br>
            Tel: (11) 4765-0757</br></br>
            Caso o Coraliado não anexe a NFS-e dentro de 3 (três) meses da apuração da Comissão e seguindo os parâmetros acima, nenhum valor será devido pela Cora. Caso o Coraliado emita a NFS-e de forma errada, precisará cancelá-la na prefeitura e emitir novamente dentro do prazo acima disposto.</br></br>
            A Cora possui o prazo de 60 (sessenta) dias para validar as informações da NFS-e e realizar o pagamento após o anexo ser submetido pelo Coraliado.
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    <br /><br />
    <strong>CONSIDERANDO QUE:</strong>
    <p>(i)   A CORA é uma sociedade regularmente constituída, autorizada pelo Banco Central e em funcionamento no Brasil, que oferece no mercado diversos serviços e produtos financeiros ("Serviços") e deseja ampliar a divulgação de seus Serviços ("Atividades da CORA");</p>
    <p>(ii)     A PARCEIRA/o CORALIADO tem interesse e deseja estabelecer parceria para promover a comercialização dos Serviços da CORA a novos clientes ("CLIENTES") nos termos, condições e comissionamentos estabelecidos neste Termos e Condições.</p>
    <p>RESOLVEM, as Partes, firmar o presente Termos e Condições de Parceria Comercial ("Termos"), com as cláusulas e condições abaixo, que mutuamente aceitam e outorgam, a saber:</p>
    <br />
    DEFINIÇÕES IMPORTANTES<br />
    Nestes Termos de Uso, os termos abaixo iniciados em letras maiúsculas, no singular ou no plural, deverão ter o seguinte significado:<br/>
    Coraliado: Empresa PARCEIRA que aceitou estes Termos de Uso e concorda com o Programa de indicação de novos clientes.<br/>
    Indicação Válida: Uma indicação de um cliente do Coraliado, via link de indicação do Coraliado disponibilizado na CoraWeb e no Aplicativo Cora, finalizada com sucesso e que se torne conta ativada.<br/>
    Conta Ativada: Usuário indicado pelo Coraliado que fez ao menos 01 (uma) Movimentação Válida em sua Conta Cora.<br/>
    Conta Ativa: Para apuração do Programa Níveis de Parcerias, será considerada como ativa, a Indicação Válida que possuir qualquer movimentação na Conta Cora nos últimos 30 (trinta) dias.<br/>
    Movimentação Válida: Qualquer movimentação em dinheiro feita pelo cliente em sua Conta Cora, seja por meio de PIX, depósito ou pagamento de boleto.
    <div/>`,
  },
  section: [
    {
      list: [
        {
          head: 'Objeto',
          items: [
            {
              item:
                'A CORA, de forma autônoma e exclusiva, avaliará a pertinência e interesse em abrir a Conta do cliente indicado pela PARCEIRA, mediante critérios de qualificação e compliance do cliente. A PARCEIRA atuará como mera intermediadora desta relação, responsável unicamente por conectar ambas as partes. Se por qualquer razão, a CORA optar por não celebrar contrato com o cliente indicado e intermediado pela PARCEIRA, nada será devido à PARCEIRA a título de Comissão.',
            },
            {
              item:
                ' Conforme ajuste estabelecido entre as Partes, a atuação da PARCEIRA se dará sem caráter de exclusividade de atuação, podendo ambas as Partes celebrarem parcerias idênticas com quaisquer terceiros.',
            },
          ],
        },
        {
          head: 'Remuneração e forma de pagamento',
          intro: (
            <>
              A CORA pagará à PARCEIRA, a título de remuneração, uma comissão
              calculada sobre as Contas Ativadas por indicação, conforme valores
              e critérios de pagamento que podem ser verificados pelo site:{' '}
              <a
                target="blank"
                href="https://comunidade.cora.com.br/termo/coraliados/comissionamento/"
              >
                https://comunidade.cora.com.br/termo/coraliados/comissionamento/
              </a>
            </>
          ),
          items: [
            {
              item:
                'Conforme convencionado entre as Partes, para a presente contratação, Receita Líquida corresponderá à comissão relativa à indicação válida feita pela PARCEIRA, deduzidos os custos operacionais envolvidos e os tributos impostos e taxas incidentes.',
            },
            {
              item:
                'Na hipótese de desligamento do CORALIADO, a PARCEIRA fará jus ao recebimento das Comissões relativas a todos as Contas Ativadas na CORA até a data do efetivo encerramento da parceria, salvo rescisão contratual com base nas letras “c” e “d” da Cláusula 8 deste Termo.',
            },
            {
              item:
                'As Partes reconhecem que para a Comissão ser paga à PARCEIRA serão consideradas como Indicação Válida e/ou abertura de Contas Ativadas aquelas que forem feitas a partir da indicação da PARCEIRA e que significarem contas novas, ou seja, de CNPJ não existentes na base da CORA e que estejam em conformidade quanto à validade de informações de cadastro, caso contrário não gera obrigação de pagamentos da Comissão.',
            },
            {
              item:
                'A PARCEIRA poderá acompanhar o status e a evolução das indicações realizadas dentro do ambiente da CoraWeb e no Aplicativo Cora.',
              child: [
                {
                  item:
                    'A PARCEIRA poderá acompanhar o status e a evolução das indicações realizadas dentro do ambiente da CoraWeb e no Aplicativo Cora.',
                },
                {
                  item:
                    'Caso a PARCEIRA discorde dos status das indicações, deverá enviar à CORA a contestação até que se esclareçam. ',
                },
              ],
            },
            {
              item:
                'A CORA pagará à PARCEIRA os valores devidos através de transferência bancária para a conta corrente de titularidade PARCEIRA, conforme descrito no Quadro Resumo, valendo o recibo de depósito como comprovante de quitação da obrigação.',
            },
            {
              item:
                'Eventual atraso no pagamento pela CORA estarão sujeitos a correção monetária de acordo com a variação do IGPM/FGV, multa de 2% (dois por cento) e juros de mora de 1% (um por cento) ao mês ou fração.',
            },
            {
              item:
                'A retenção e o pagamento dos tributos, taxas, ou contribuições que incidam ou venham a incidir sobre o presente Termos e Condições serão suportados pela Parte responsável segundo a legislação aplicável.',
            },
            {
              item:
                'Em caso de haver uma falha técnica e o pagamento da Comissão ser feito em duplicidade ou a mais, a PARCEIRA deverá devolver este valor à CORA ou deduzi-lo de futuras comissões, a critério da CORA, após formalização por e-mail.',
            },
            {
              item:
                'Caso a Conta Cora da PARCEIRA seja encerrada, seja por sua iniciativa ou da Cora, o presente Contrato de Parceria deixa de ter validade, logo, quaisquer valores de Comissão pendentes de pagamento, referente ao período em que a Parceria era válida, devem ser pagos através de conta bancária de mesma titularidade do Coraliado, a ser indicada por e-mail para <a href="mailto:parcerias@cora.com.br">parcerias@cora.com.br</a>, em até 30 (trinta) dias do encerramento da Conta Cora.',
                child: [
                  {
                    item:
                      'Caso o Coraliado não envie as informações bancárias dentro prazo determinado na cláusula acima, nada mais será devido pela Cora ao Coraliado. Após o envio das informações bancárias, a Cora terá o prazo de 90 (noventa) dias para apurar as indicações pendentes de pagamento e realizar o pagamento ao Coraliado cuja conta foi encerrada.',
                  },
                ],
            },
            {
              item:
                'Caso a Conta Cora da PARCEIRA seja limitada por qualquer infração dos Termos e Condições Gerais de Uso, e possua quaisquer valores de Comissão pendentes de pagamento, estes serão suspensos até a regularização da Conta Cora. Uma vez que a Conta da PARCEIRA seja regularizada, o pagamento em aberto será apurado e pago no prazo de 90 (noventa) dias.',
            },
          ],
        },
        {
          head: 'Obrigações do Coraliado',
          intro: (
            <>
              Obriga-se o CORALIADO a: <br />
              <br />
              a. envidar seus melhores esforços para divulgar esta Parceria
              durante a validade deste Termos e Condições; <br />
              <br />
              b. ser unicamente responsável por todos os custos e despesas
              referentes a sua operação e de pessoal incluindo-se ainda todas as
              obrigações trabalhistas e de treinamento dos seus profissionais;
              <br />
              <br />
              c. divulgar de forma eficaz, nos termos do material de marketing a
              ser definido e produzido pela CORA, relativamente à oferta dos
              Serviços;
              <br />
              <br />
              d. garantir que todos os materiais de marketing definidos pela
              CORA sejam utilizados estritamente para cumprimento do objeto
              deste Termos e Condições;
            </>
          ),
          items: [
            {
              item:
                'Não serão aceitas indicações atribuídas manualmente de CNPJ de clientes de outros CORALIADOS, configurando má-conduta.',
            },
            {
              item: `A PARCEIRA confirma que leu e declara que está apto a cumprir, durante a vigência desta relação de parceria comercial, a “Política de Prevenção a Lavagem de Dinheiro e Financiamento ao Terrorismo e Proliferação” da Contratante, a qual está disponível para consulta e download no site <a target='blank' href="https://www.cora.com.br/static/Cora_SCD_Politica_PLDFT-64527b941a26989593c21452011434e5.pdf">https://www.cora.com.br/static/Cora_SCD_Politica_PLDFT-64527b941a26989593c21452011434e5.pdf</a>`,
            },
            {
              item: `A PARCEIRA se obriga por si e por todos os seus funcionários, representantes legais, contratados e subcontratados a cumprir o “Código de Ética e Conduta” da Contratante, sendo que ele sempre estará disponível para consulta e download no seguinte site <a target='blank' href="https://www.cora.com.br/static/codigo_de_etica_e_conduta-0c4167adcc4023ea52c9a079ea5ab6c3.pdf">https://www.cora.com.br/static/codigo_de_etica_e_conduta-0c4167adcc4023ea52c9a079ea5ab6c3.pdf</a>`,
            },
            {
              item:
                'O não cumprimento pela PARCEIRA no disposto na “Política de Prevenção à Lavagem de Dinheiro e Financiamento ao Terrorismo e Proliferação” e ao “Código de Ética e Conduta”, poderá dar à Contratante o direito de promover a rescisão imediata desta relação de parceria comercial.',
            },
          ],
        },
        {
          head: 'Obrigações da Cora',
          intro: (
            <>
              Obriga-se a CORA a: <br />
              <br />
              a. disponibilizar à PARCEIRA todas as informações e conteúdo
              necessários ao fiel e bom cumprimento do objeto do presente
              instrumento, inclusive os materiais de marketing;
              <br />
              <br />
              b. pagar à PARCEIRA a remuneração devida, na forma e prazos
              previstos;
              <br />
              <br />
              c. encaminhar/disponibilizar à PARCEIRA, sempre que solicitado,
              relatório referente ao andamento dos negócios que se iniciaram
              pela indicação e referência deste, incluindo os pagamentos feitos
              e despesas associadas à parceria comercial;
              <br />
              <br />
              d. manter relatório detalhado das indicações realizadas e dos
              negócios indicados efetivamente realizados decorrentes desta
              parceria;
            </>
          ),
          items: [],
        },
        {
          head: 'Independência das partes',
          items: [
            {
              item:
                'As Partes obrigam-se a realizar as suas atividades utilizando profissionais especializados e em número suficiente, cabendo-lhe total e exclusiva responsabilidade pelo integral atendimento de toda a legislação que rege os negócios jurídicos e que lhe atribua responsabilidades.',
            },
            {
              item:
                'O presente Termos e Condições não resulta, em hipótese alguma, vínculo de natureza trabalhista ou associativa entre as partes, nem tampouco entre qualquer delas e os sócios, funcionários, prepostos ou contratados da outra, respondendo cada uma, individual e isoladamente, por todas as obrigações que assumir, seja de natureza civil, criminal, trabalhista, tributária, previdenciária, securitária ou de qualquer outra natureza for. Os atos decorrentes deste Termos e Condições são de natureza civil e comercial. As partes, para todos os efeitos, declaram, neste ato, a total desvinculação e independência de seus negócios, sendo certo que em nenhum momento poderão alegar qualquer vinculação de ordem societária ou administrativa, comprometendo-se, única e exclusivamente, com a relação comercial ora regulada, ou seja, além da parceria ora regulada, não se estabelece, nem se estabelecerá, entre as partes, por força deste Termos e Condições, qualquer forma de sociedade, associação, joint venture, parceria, consórcio ou responsabilidade solidária.',
            },
            {
              item:
                'Na eventual hipótese de vir a ser proposta contra uma das Partes qualquer ação, seja de natureza civil, criminal, trabalhista, tributária, previdenciária, securitária, ou ainda de qualquer outra natureza, por qualquer um dos sócios, contratados, prepostos e funcionários da outra Parte, esta será notificado do fato e deverá assumir o pólo passivo da ação na qualidade de substituto processual e deverá promover a defesa da ação, suportando diretamente todos os custos, honorários e despesas processuais, assim como eventuais indenizações e condenações. Nessas hipóteses, estarão as Partes autorizadas a reter de quaisquer valores devidos à outra Parte as quantias necessárias a cumprir as obrigações aqui listadas e, em sendo insuficientes, poder adotar as medidas judiciais e administrativas cabíveis para obter a reparação do que faltar.',
            },
          ],
        },
        {
          head: 'Sigilo, Confidencialidade',
          items: [
            {
              item:
                'As partes obrigam-se, direta e/ou indiretamente, por si, pelos seus representantes, seus sócios, administradores, prepostos, empregados, contratados, ou colaboradores, seja qual for o título sob que atuem, a manter total sigilo e confidencialidade em relação a todos os termos e condições deste instrumento, bem como do relacionamento comercial havido e todos e quaisquer dados, correspondências, documentos e informações vinculados a esse relacionamento, estabelecidas pelas partes de forma oral ou escrita ("<u>Informações Comerciais</u>"), as quais não poderão, a qualquer título ou sob qualquer pretexto, serem reveladas e/ou transferidas a quaisquer terceiros, não signatários deste instrumento.',
            },
            {
              item:
                'As partes se obrigam, de forma irretratável e irrevogável, a manter sob sigilo absoluto todas e quaisquer Informações Confidenciais.',
            },
            {
              item:
                'As partes comprometem-se a limitar a divulgação das "Informações Confidenciais" somente àqueles que, em decorrência de suas atividades, necessitem tomar conhecimento das mesmas.',
            },
            {
              item:
                'Caso as partes venham a ser obrigadas, por imposição legal ou por determinação de autoridade devidamente constituída, a divulgar certas ou determinadas Informações Confidenciais, as mesmas se comprometem a restringir essa divulgação ao estritamente necessário ao atendimento da imposição ou da determinação, bem como a, imediatamente, notificar a outra parte acerca desse fato;',
            },
            {
              item:
                'Não serão consideradas "Informações Confidenciais", para os efeitos deste instrumento, as informações, dados, materiais e suas descrições, documentos, especificações técnicas ou comerciais, inovações ou aperfeiçoamentos que: (a) na ocasião da sua divulgação por uma Parte, já forem comprovadamente do conhecimento da outra Parte, desde que tal conhecimento prévio tenha sido obtido de forma considerada legítima; (b) sejam de domínio público; (c) sejam objeto de permissão escrita, respeitados os limites e condições dispostos na permissão para a divulgação das informações; (d) sejam requisitadas por ordem judicial e/ou da Administração Pública ou cuja divulgação seja determinada por lei, respeitados os estritos limites da requisição ou determinação; e (e) tenham por finalidade atingir e/ou aprimorar os objetivos deste instrumento.',
            },
            {
              item:
                'As Partes reconhecem que o presente Termos e Condições não representa em qualquer forma de cessão e/ou transferência, de uma parte a outra, de direitos de quaisquer marcas, site, obras, textos, softwares, projetos, aplicativos, parametrizações, desenvolvimentos, eventuais customizações, processos, metodologias aplicadas nas soluções, metodologias de descrição de materiais e bens imateriais, incluindo, mas não limitados a, marcas, patentes, know-how de titularidade da outra Parte e/ou seus licenciadores, registradas ou de uso corrente no Brasil e/ ou no exterior, e se compromete a respeitar a titularidade sobre as mesmas, não registrando ou reivindicando quaisquer direitos sobre tais marcas.',
            },
          ],
        },
        {
          head: 'Propriedade Intelectual',
          items: [
            {
              item: `Pelo presente Instrumento, a PARCEIRA está autorizada a utilizar o nome, marca e/ou logomarca da CORA no exato limite necessário à viabilizar a parceria comercial, considerando, <u>obrigatoriamente, as orientações e formatos definidos e apresentados pela CORA</u>, tanto na utilização de seus signos distintivos, quanto em relação a seus produtos, conforme materiais de marketing fornecidos pela CORA. Ressalvada a condição aqui apresentada, o presente Termos e Condições não deve ser interpretado, em hipótese alguma, como cessão e/ou transferência, total ou parcial, à PARCEIRA e/ou seus sócios, empregados ou prepostos, do nome, marca, logotipos, logomarcas, marcas de serviços, sites, insígnias, símbolos, nomes comerciais, denominações e quaisquer outros sinais distintivos de titularidade ou utilizados pela CORA (<u>"Marcas da CORA"</u>).`,
            },
            {
              item:
                'Quaisquer contatos telefônicos, por e-mail ou outros meios de comunicação com potenciais CLIENTES, em nome da CORA, devem ser aprovados por escrito pela CORA.',
            },
            {
              item:
                'A autorização de uso das Marcas da CORA está condicionada à vigência e limites deste Termos e Condições, obrigando-se a PARCEIRA, por si e/ou por seus prepostos, neste ato, a não adquirir, reproduzir, vender, publicar ou de qualquer modo explorar as Marcas da CORA, ou quaisquer objetos de propriedade intelectual da CORA, em desacordo com os termos ora fixados.',
            },
            {
              item:
                '<u>Limites de Uso das Marcas CORA.</u> A autorização concedida pela CORA à PARCEIRA, neste ato, se dá de forma não exclusiva, gratuita e vinculada à vigência desta Parceria, sendo vedado à PARCEIRA a criação e/ou divulgação de qualquer material de comunicação que envolva as Marcas da CORA sem a prévia e expressa anuência escrita da CORA.',
              child: [
                {
                  item:
                    '<u>Materiais promocionais.</u> A PARCEIRA deverá solicitar previamente à CORA autorização  expressa e específica, por escrito, para usar, reproduzir, distribuir, realizar ações ou campanhas e exibir as Marcas CORA em quaisquer materiais promocionais que: (i) direta ou indiretamente estejam relacionadas à presença e preferência da CORA e/ou se refiram a quaisquer dos seus produtos; e/ou (ii) tenha como público alvo os CLIENTES. <u>O silêncio da CORA deverá ser interpretado com recusa na autorização.</u>',
                },
                {
                  item:
                    'Fica expressamente vedado à PARCEIRA, ainda e de forma não taxativa, utilizar as Marcas da CORA, no todo e/ou em parte, para: (i) indicação como títulos de estabelecimentos comerciais próprios; (ii) utilização para designação de razão social de sua empresa; (iii) apresentação, perante o INPI, de novos pedidos de registro de marcas idênticas e/ou semelhantes, ou ainda, que de qualquer forma, possam ser confundidas pelo público em geral com as Marcas da CORA; (iv) inserção em avisos e/ou cartões comerciais; (v) produção de materiais de escritório; (vi) produção de formulários ou materiais de qualquer espécie da PARCEIRA.',
                },
              ],
            },
            {
              item:
                ' A CORA poderá, a qualquer tempo e a seu exclusivo critério, alterar, restringir, modificar, cessar a Autorização de Uso das Marcas CORA concedida, devendo a PARCEIRA, diretamente ou pelas pessoas designadas para tanto, cessar imediatamente o uso das Marcas da CORA, devendo imediatamente devolver qualquer material que esteja em sua posse à CORA, imediatamente após a solicitação escrita por esta apresentada.',
            },
            {
              item:
                '<u>Aprovação do uso das Marcas CORA.</u> A PARCEIRA enviará à CORA para análise prévia e aprovação escrita, todos os conteúdos e/ou materiais nos quais pretenda incluir as Marcas CORA. A falta da CORA em comunicar sua aprovação ou desaprovação, isto é, o silêncio da CORA, não poderá ser considerada, para todos os efeitos, como sua aprovação tácita.',
            },
            {
              item:
                '<u>Uso da Imagem e da Marca do CORALIADO.</u> A CORA pode utilizar a marca, à título gratuito, os sinais distintivos, a imagem, o estabelecimento comercial e o nome empresarial do CORALIADO, no que tange a pessoa jurídica e a imagem, nome e voz, no que tange pessoa física do CORALIADO, em ações de marketing que têm por finalidade a inserção em materiais de divulgação interno e externo, a veiculação em mídias digitais/eletrônicas e outras que existam ou venham a existir no futuro, bem como em perfis institucionais da CORA em redes sociais já existentes ou que venham a ser criadas, no site institucional da CORA na rede mundial de computadores e em qualquer material de divulgação institucional da CORA.',
              child: [
                {
                  item:
                    'O CORALIADO, ao participar das campanhas promocionais e de divulgação da marca de sua empresa realizadas pela Cora, concorda em divulgar o telefone, endereço e e-mail profissionais de sua contabilidade.',
                },
              ],
            },
            {
              item:
                '<u>Especificações e padrões; qualidade, modo e forma de utilização.</u> A PARCEIRA respeitará as especificações e os padrões de utilização e gráficos comunicados por escrito pela CORA. Sem prejuízo, a PARCEIRA concorda que todas as utilizações das Marcas da CORA terão o nível de qualidade que é habitual para usos semelhantes de tais Marcas, conforme critérios e padrão da CORA. Em hipótese alguma a PARCEIRA fará uso das Marcas da CORA de modo que seja eventualmente considerado ofensivo, obsceno, lascivo ou imoral, ou que reflita mal na imagem pública. A PARCEIRA concorda em incluir avisos legais e apropriados sobre propriedade e/ou registro relacionados a quaisquer e todos os usos das Marcas CORA de acordo com solicitações razoáveis da CORA.',
            },
          ],
        },
        {
          head: 'Das Condições de participção do programa Coraliados e regras de indicação',
          items: [
            {
              item: `A Cora poderá desligar o Coraliado do Programa de Parceria Comercial nas seguintes situações:<br/><br/>
              a. A qualquer momento, sem qualquer motivo ou razão, mediante notificação prévia e por escrito, com antecedência mínima de 30 (trinta) dias, sem quaisquer ônus ao pagamento das comissões pendentes de pagamento;<br/><br/>
              b. A qualquer momento, sem aviso prévio e com efeito imediato, em razão de inexecução, atraso na execução ou má-execução nas indicações de clientes, desde que tal fato não seja sanado em no máximo 15 (quinze) dias a contar da data da notificação acerca deste fato;<br/><br/>
              c. A qualquer momento, sem aviso prévio e com efeito imediato, no caso de as indicações serem realizadas sem respeitar as boas práticas de contato com clientes e potenciais clientes por meios digitais;<br/><br/>
              d. A qualquer momento, sem aviso prévio e com efeito imediato, caso seja constatada a prática de quaisquer atos que sejam nocivos à manutenção de bom nome, dos produtos, dos clientes ou dos parceiros da Cora ou que sejam contrários ao <a href="#anexo-guia-de-boas-praticas">Guia de Boas Práticas</a>;<br/><br/>
              e. O descumprimento de qualquer obrigação de zelo pela marca Cora, importará na responsabilidade da PARCEIRA pelo pagamento de indenização por perdas e danos até o limite do dano, ainda que indireto.
              `,
            },
            {
              item:
                'Subsistirão ao desligamento do Coraliado do Programa de Parceria Comercial, por qualquer motivo ou razão, por um prazo de 5 (cinco) anos a partir do seu término, as obrigações de sigilo e confidencialidade. As obrigações de propriedade intelectual, direitos autorais e proteção de dados, terão caráter perene e subsistirão por prazo indeterminado.',
            },
            {
              item:
                'Caso dois ou mais Coraliados indiquem a mesma empresa, a indicação será atribuída ao primeiro que realizou a indicação e o sistema não permitirá a inclusão de indicação por outro Coraliado que tente indicar a mesma empresa posteriormente.',
            },
            {
              item:
                'Se ao tentar indicar uma empresa, esta já conste como indicada anteriormente por outra PARCEIRA, o Coraliado poderá tentar reivindicar a investigação do caso através do email parcerias@cora.com.br, desde que a empresa indicada não tenha aberto conta na Cora ainda. O email deverá conter a solicitação da investigação, juntamente com o CNPJ da empresa indicada e provas que demonstrem vínculo comercial entre o Coraliado para com a empresa indicada.',
                child: [
                  {
                    item:
                      'Ao solicitar investigação, a Cora terá um prazo de até 30 (trinta) dias úteis para avaliar o caso e retornar sobre a decisão tomada. Os critérios avaliados poderão ser: provas enviadas pelo Coraliado, histórico de transações efetuadas na conta Cora, confirmação de vínculo realizada pela empresa indicada, entre outros. Se constatado o vínculo, a Cora poderá transferir a indicação de uma PARCEIRA para outra e, se verificada reincidência, ou seja, houverem múltiplos pedidos de revisão de indicações de um determinado Coraliado, a Cora poderá realizar descredenciamento deste Coraliado do Programa de Parceria.',
                  },
                ],
                
            },
          ],
        },
        {
          head: 'Proteção de dados pessoais',
          items: [
            {
              item: `As Partes ajustam que, caso seja necessária a troca de dados pessoais (conforme definido pela Lei 13.709/2018) para a execução dos serviços objeto do presente instrumento, somente serão fornecidos os dados estritamente necessários para o bom cumprimento deste Termos e Condições, sendo que caso a legislação exija consentimento para o tratamento e troca de informações, as Partes responsáveis deverão obter termo de consentimento claro, específico, prévio e escrito do titular dos dados e/ou de seu representante legal.
              `,
            },
            {
              item:
                'No desenvolvimento de quaisquer atividades relacionadas à execução deste Termos e Condições, as Partes deverão observar o regime legal da proteção de dados pessoais, empenhando-se em proceder a todo o tratamento de dados pessoais que venha a mostrar-se necessário no estrito e rigoroso cumprimento da Lei nº 13.709/2018, obrigando-se a: a. tratar e usar os dados pessoais nos termos legalmente permitidos e de modo compatível com as finalidades para os quais tenham sido fornecidos; b. conservar os dados apenas durante o período necessário à prossecução das finalidades de coleta ou do tratamento posterior; c. implementar medidas técnicas e organizacionais para proteger os dados contra a destruição, acidental ou ilícita, a perda acidental, a alteração, a difusão ou o acesso não autorizado, bem como contra qualquer outra forma de tratamento ilícito; d. informar com a máxima brevidade a outra Parte, caso exista qualquer incidente de segurança ou sua suspeita, prestando a colaboração necessária a qualquer investigação que venha a ser realizada; e.  garantir o exercício, pelos titulares, dos respetivos direitos de informação e acesso aos dados pessoais; f. assegurar que seus colaboradores e/ou prestadores de serviços que venham a ter acesso a dados pessoais em face desse Termos e Condições, cumpram as disposições legais aplicáveis em matéria de proteção de dados pessoais, e deles façam uso tão somente para os fins consentidos pelos respectivos titulares ou, para atender exigências das Autoridades Reguladoras de proteção de dados.',
            },
            {
              item:
                'Eventual descumprimento da legislação de proteção de dados aplicável sujeitará a Parte infratora ao dever de assumir total responsabilidade e ressarcir a Parte inocente e/ou o titular do dado, por todos os danos e prejuízos sofridos, incluindo eventuais multas aplicadas pelas autoridades.',
            },
            {
              item:
                'As Partes estão obrigadas a guardar confidencialidade sobre todos os dados pessoais a que tiver acesso em virtude ou em consequência deste Termos e Condições, devendo assegurar que seus diretores, colaboradores, prepostos, consultores, subcontratados e/ou prestadores de serviços se encontram contratualmente obrigados a sigilo profissional caso venham a ter acesso e/ou conhecimento dos dados pessoais tratados.',
            },
          ],
        },
        {
          head: 'Do programa Níveis de Parceria',
          items: [
            {
              item: `O Programa Níveis de Parceria é composto por diferente níveis de Coraliados, a depender do número de Indicação Válida que se enquadra no conceito de Conta Ativa, conforme definição destes termos, iniciados em letra maiúscula, na seção de Definições Importantes deste Termo.`,
            },
            {
              item: `Os níveis seguem a seguinte configuração de indicações: <br/>
              Nível 1 - Habilitado - 0 a 2 indicações ativas <br/>
              Nível 2 - Rubi - 3 a 9 indicações ativas <br/>
              Nível 3 - Esmeralda - 10 a 24 indicações ativas <br/>
              Nível 4 - Safira - 25 a 149 indicações ativas <br/>
              Nível 5 - Diamante - 150+ indicações ativas <br/>
              `,
            },
            {
              item: `Uma vez que as apurações do Programa Níveis de Parceria ocorrem periodicamente, o CORALIADO pode sofrer variação no seu nível de parceria a depender da flutuação do número de Contas Ativas.`,
            },
            {
              item: `Os benefícios de cada Nível de Parceria estão relacionados como Anexo a este Termo e são parte integrante do presente documento. Alterações e/ou cancelamentos nos benefícios podem ocorrer sem aviso prévio. A Cora pode oferecer, a seu exclusivo critério e sem periodicidade definida, benefícios de um nível acima para um Coraliado em um nível abaixo do Programa.`,
            },
          ],
        },
        {
          head: 'Disposições gerais',
          items: [
            {
              item: `O presente Termos e Condições é celebrado em caráter irrevogável e irretratável, obrigando seus herdeiros e/ou sucessores, não podendo ser cedido para terceiros, inclusive para empresas de seu próprio grupo, os créditos e/ou obrigações de qualquer natureza decorrentes ou oriundos do Contrato, ou parte dele, salvo mediante anuência escrita da outra parte.`,
            },
            {
              item:
                'Nenhuma das partes será responsável ou considerada inadimplente por atrasos no cumprimento do Termos e Condições, ocasionados, comprovadamente por casos fortuitos e força maior ou qualquer causa alheia ao controle possível das partes.',
            },
            {
              item:
                'As Partes garantem que têm os direitos, poder e autoridade para celebrar e executar este Termos e Condições na íntegra, sujeitas as responsabilidades civis e criminais por qualquer declaração falsa.',
            },
          ],
        },
        {
          head: 'Foro',
          items: [
            {
              item: `As partes elegem o foro central da comarca de São Paulo, Estado de São Paulo, para dirimir, as dúvidas ou questões que porventura surgirem durante a vigência do presente Acordo, renunciando, expressamente a qualquer outro, por mais privilegiado que possa parecer.<br/><br/>

              Estes Termos e Condições e a Política de Privacidade vigerão por prazo indeterminado, a partir de sua aceitação por Sua Empresa.<br/><br/>`,
            },
          ],
        },
      ],
    },
    {
      title: `Anexo - Benefícios Programa Níveis de Parceria`,
      list: [],
      attachment: (
        <div className="attachment">
          <img src={AttachmentRelationship} />
        </div>
      ),
    },
    {
      title: `Anexo - Guia de Boas Práticas`,
      list: [],
      attachment: (
        <div className="space">
          <Link href={MaterialGuide} target="_blank">Guia de Boas Práticas</Link>
        </div>
      ),
    },
  ],
}
