import React from 'react'

import TermsPages from '../partials/Terms/About'
import { dataTermsCoraliados } from '../data/terms/terms-coraliados'

const CoraliadosTermsConditionsPage = (): JSX.Element => (
  <TermsPages
    data={dataTermsCoraliados}
    linkCanonical="termos-e-condicoes-de-coraliados"
  />
)

export default CoraliadosTermsConditionsPage
